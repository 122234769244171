import React, { useEffect, useRef, useState } from 'react'
import "./UseCases.css"
import { AnimatePresence, motion } from "framer-motion"
import { FaRightLong, FaLeftLong } from "react-icons/fa6";

import image1 from "../../../Assets/UseCases/culture.webp"
import image2 from "../../../Assets/UseCases/social.webp"
import image3 from "../../../Assets/UseCases/email.webp"
import image4 from "../../../Assets/UseCases/homepage.webp"
// import image5 from "../../../Assets/UseCases/landing.png"
// import image6 from "../../../Assets/UseCases/landing.png"

import video1 from "../../../Assets/UseCases/Culture.mp4";
import video2 from "../../../Assets/UseCases/Social-Ad.mp4";
import video3 from "../../../Assets/UseCases/Email-Marketing1.mp4" 
import video4 from "../../../Assets/UseCases/Home-Page.mp4"
// import video5 from "../../../Assets/UseCases/2.mp4"

import play from "../../../Assets/Common/play-button.svg"
import closeButton from "../../../Assets/Common/close-button1.svg"
// import ReactPlayer from 'react-player';

const items = [
    {
        id: 1,
        title: "Culture Video",
        img: image1,
        video: video1,
        description1: "Our culture-focused animation service offers a unique, handcrafted approach to enrich brand storytelling and showcase your brand’s essence in a captivating way.",
        description2: "Using video to promote culture allows Rabbit And Carrot to highlight what makes your brand unique, from its mission to the people behind it. Our videos engage audiences by authentically showcasing your company’s story, fostering stronger connections, and creating a memorable impression that builds trust and loyalty."
    },
    {
        id: 2,
        title: "Social Ad Video",
        img: image2,
        video: video2,
        description1: "Our social ad animation service brings a distinctive, handcrafted approach to elevate your brand storytelling and engage your audience like never before.",
        description2: "Our Social Ad Video service at Rabbit And Carrot is designed to captivate your audience on social media platforms, delivering your message with impact and style. We create high-quality, engaging videos tailored for platforms like Instagram, Facebook, and TikTok to increase visibility, drive interaction, and boost conversions."

    },
    {   
        id: 3,
        title: "Email Marketing Video",
        img: image3,
        video: video3,
        description1: "Our email marketing video service is designed to boost your brand’s storytelling with a unique, personalized approach that simplifies complex messages and captivates your audience.",
        description2: "Our Email Marketing Video service brings your messages to life, helping you cut through inbox clutter and capture your audience’s attention. We craft personalized, high-quality videos that engage viewers, boost open rates, and drive action. Whether it's showcasing a product, sharing a story, or delivering a company update, our email videos are optimized for quick loading and mobile viewing, ensuring they reach your audience effectively."

    },
    {
        id: 4,
        title: "Home Page Video",
        img: image4,
        video: video4,
        description1: "Our home page animation service is crafted to enhance brand storytelling, delivering a unique, handcrafted experience that captivates visitors and brings your brand to life.",
        description2: "Our Home Page Videos are designed to create an unforgettable first impression, capturing your audience’s interest the moment they land on your website. These videos are optimized for fast loading and mobile responsiveness, ensuring a smooth viewing experience across all devices. Transform your home page into a dynamic and engaging experience with compelling video content."

    },
    // {
    //     id: 5,
    //     title: "Landing Page Video",
    //     img: image5,
    //     video: video5,
    //     description1: "Our landing page video animation service is designed to enhance brand storytelling with a distinctive, handcrafted touch that captivates visitors and drives conversions.",
    //     description2: "Our Landing Page Videos are designed to captivate your audience instantly and drive conversions like never before. By incorporating high-quality, engaging videos into your landing pages, you can effectively communicate your message, showcase your product or service, and guide viewers toward taking action. These videos are crafted to be visually compelling, optimized for fast loading, and fully responsive across devices, ensuring a seamless experience for every visitor. Whether you're aiming to boost sign-ups, generate leads, or promote a special offer, our landing page videos create a dynamic and persuasive user journey. Elevate your landing pages with impactful video content that inspires and converts."

        
    // },
    // {
    //     id: 6,
    //     title: "Sales Enablement Video",
    //     img: image6,
    //     video: video1,
    //     description1: "Our sales enablement animation service is crafted to enhance brand storytelling with a unique, handcrafted approach that empowers your sales team and drives customer engagement.",
    //     description2: "Our Sales Enablement Videos empower your sales team to connect with prospects, close deals faster, and drive results. These videos are tailored to highlight your value proposition, address customer pain points, and showcase your solutions in a clear and engaging way. From product demos to case studies and personalized messages, our high-quality videos simplify complex information, build trust, and keep your audience engaged. Optimized for quick sharing and mobile viewing, these videos ensure your sales team has the tools they need to leave a lasting impression and drive meaningful conversations. Equip your sales strategy with compelling video content that delivers results."
    // }
]

export default function UseCases({ size }) {
    const [playingVideo, setPlayingVideo] = useState(null);
    const [currentUseCase, setCurrentUseCase] = useState(items.find(ele => ele.video === playingVideo))

    const handleVideoClick = (videoUrl) => {
        setPlayingVideo(videoUrl)
    };

    // console.log(currentUseCase)

    useEffect(() => {
        setCurrentUseCase(items.find(ele => ele.video === playingVideo))
    }, [setCurrentUseCase, playingVideo])

    // const secondContainerRef = useRef(null);

    // const scrollToSecondContainer = () => {
    //     if (secondContainerRef.current) {
    //         secondContainerRef.current.scrollIntoView({ 
    //             behavior: "smooth",
    //             inline: "start", // Aligns horizontally
    //             block: "nearest" 
    //         });
    //     }
    // };

    const containerRefs = useRef([]); // Array to store references to each container
    const [currentIndex, setCurrentIndex] = useState(0); // State to track the current container index

    const scrollToNextContainer = () => {
        if (currentIndex < containerRefs.current.length - 1) {
            const nextIndex = currentIndex + 1;
            containerRefs.current[nextIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
            setCurrentIndex(nextIndex); // Update the current index
        } else {
            // Loop back to the first container
            const firstIndex = 0;
            containerRefs.current[firstIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
            setCurrentIndex(firstIndex); // Reset the current index
        }
    };

    // function adjustOverlayHeight() {
    //     const images = document.querySelectorAll('.usecases-container .left img', '.usecases-container .right img');
    //     const overlays = document.querySelectorAll('.usecases-container .overlay', '.usecases-container .overlay1');

    //     images.forEach((img, index) => {
    //         const overlay = overlays[index];
    //         if (overlay && img.complete) {
    //         overlay.style.height = `${img.offsetHeight}px`;
    //         }
    //     });
    // }
    
    //   useEffect(() => {
    //     adjustOverlayHeight();
    //     window.addEventListener("resize", adjustOverlayHeight);
    //     return () => window.removeEventListener("resize", adjustOverlayHeight);
    //   }, []);

    return (
        <section id="usecases" className="usecases">
            <h1 className="commonHeading">Use Cases</h1>
            {/* <div className="heading-div">
                <h1>
                    <hr />Use Cases
                </h1>
            </div> */}
            <div className="scroll-button">
                <motion.button 
                    initial= {{
                        opacity: 0,
                        x: 0
                    }}
                    animate={{
                        opacity: 1,
                        x: 50,
                        transition: {
                            duration: 2,
                            repeat: Infinity
                        }
                    }}
                    onClick={scrollToNextContainer}>
                    Scroll For More <FaRightLong className="arrow"/>
                </motion.button>
            </div>
            <div className="usecases-main-container">
                <div className="usecases-container" ref={(el) => (containerRefs.current[0] = el)}>
                    <div className="left">
                        <motion.img 
                            initial={{
                                opacity: 0,
                                y: -50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            src={image1} alt="2D Animation House" 
                            onClick={() => handleVideoClick(items[0].video)}
                            // className="usecases-img"
                        />
                        <div className="overlay">
                            <div className="row">
                                {/* <p className="name">VIDEO PRODUCTION</p> */}
                                <img className="play-button" src={play} alt=""/>
                                {/* <p className="company-name">KNORR</p> */}
                            </div>
                        </div>
                        <motion.div
                            initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}>
                            <h1>{items[0].title}</h1>
                            <p>{items[0].description1}</p>
                        </motion.div>
                    </div>
                    <div className="right">
                        <motion.div
                            initial={{
                                opacity: 0,
                                y: -50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}>
                            <h1>{items[1].title}</h1>
                            <p>{items[1].description1}</p>
                        </motion.div>
                        <motion.img 
                            initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            src={image2} alt="2D Storytelling Services"
                            onClick={() => handleVideoClick(items[1].video)}
                        />
                        <div className="overlay1">
                            <div className="row">
                            {/* <p className="name">VIDEO PRODUCTION</p> */}
                                <img className="play-button" src={play} alt=""/>
                            {/* <p className="company-name">KNORR</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="usecases-container" ref={(el) => (containerRefs.current[1] = el)}>
                    <div className="left">
                        <motion.img 
                            initial={{
                                opacity: 0,
                                y: -50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            src={image3} alt=""
                            onClick={() => handleVideoClick(items[2].video)} 
                        />
                        <div className="overlay">
                            <div className="row">
                                <img className="play-button" src={play} alt=""/>
                            </div>
                        </div>
                        <motion.div
                            initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}>
                            <h1>{items[2].title}</h1>
                            <p>{items[2].description1}</p>
                        </motion.div>
                    </div>
                    <div className="right">
                        <motion.div
                            initial={{
                                opacity: 0,
                                y: -50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}>
                            <h1>{items[3].title}</h1>
                            <p>{items[3].description1}</p>
                        </motion.div>
                        <motion.img 
                            initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            src={image4} alt="2D Animation Production"
                            onClick={() => handleVideoClick(items[3].video)}
                        />
                        <div className="overlay1">
                            <div className="row">
                            <   img className="play-button" src={play} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="usecases-container" ref={(el) => (containerRefs.current[2] = el)}>
                    <div className="left">
                        <motion.img 
                            initial={{
                                opacity: 0,
                                y: -50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            src={image5} alt=""
                            onClick={() => handleVideoClick(items[4].video)}
                        />
                        <div className="overlay">
                            <div className="row">
                                <img className="play-button" src={play} alt=""/>
                            </div>
                        </div>
                        <motion.div
                            initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}>
                            <h1>{items[4].title}</h1>
                            <p>{items[4].description1}</p>
                        </motion.div>
                    </div>
                    <div className="right">
                        <motion.div
                            initial={{
                                opacity: 0,
                                y: -50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}>
                            <h1>{items[5].title}</h1>
                            <p>{items[5].description1}</p>
                        </motion.div>
                        <motion.img 
                            initial={{
                                opacity: 0,
                                y: 50
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            viewport={{ once: false, amount: 0.5 }}
                            src={image6} alt=""
                            onClick={() => handleVideoClick(items[5].video)}
                        />
                        <div className="overlay1">
                            <div className="row">
                                <img className="play-button" src={play} alt=""/>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <AnimatePresence>
                {playingVideo && (
                    <motion.div 
                        className="video-overlay" 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="overlay"></div>
                        <motion.div 
                            className="video-player"
                            initial={{ scale: 0.5 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                            transition={{ duration: 0.5 }}
                        >
                            <video src={playingVideo} autoPlay loop muted/>
                            <div className="video-content">
                                <h1>{currentUseCase?.title.toUpperCase()}</h1>
                                <h3>WHY USE VIDEO TO PROMOTE {currentUseCase?.title.replace(/\bVideo\b/i, '').trim().toUpperCase()}?</h3>
                                <p>{currentUseCase?.description2}</p>
                            </div>
                        </motion.div>
                        <motion.img 
                            animate={{
                                scale: [1, 1.2, 1.2, 1],
                                rotate: [0, 90, 90, 0],
                                borderRadius: [ "50%"]
                            }}
                            transition={{
                                duration: 5,
                                ease: "easeInOut",
                                repeat: Infinity,
                                repeatDelay: 1
                            }}
                            whileTap={{ scale: 0.9}}
                            whileHover={{
                                scale: 1.2,
                                background: "#BE3030",
                                color: "white"
                            }}
                            drag
                            dragConstraints={{
                                top: -60,
                                right: 60,
                                bottom: 0,
                                left: -60
                            }}
                            className="close-button1" onClick={() => setPlayingVideo(null)} src={closeButton}/>
                    </motion.div>
                )}
            </AnimatePresence>

        </section>
    )
}