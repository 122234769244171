import React, { useEffect, useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion"
import "./Hero.css";

// import video from "../../../Assets/Logo/video.webp";
import video from "../../../Assets/Logo/2D-SHOWREEL.mp4";
import whatsappIcon from "../../../Assets/Common/whatsapp.webp";
import mouse from "../../../Assets/Common/mouse-white.webp"
import clutch from "../../../Assets/Common/clutch-white.webp"
import ReactPlayer from "react-player";

export default function Hero({ size }) {
  const textVariants = {
    initial: {
      x: -size*5,
      opacity: 0
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.1
      }
    },
    scrollButtonInitial: {
      opacity: 0
    },
    scrollButton: {
      opacity: 1,
      y: 10,
      transition: {
        duration: 2,
        repeat: Infinity
      }
    }
  } 

  const ref = useRef()
  const {scrollYProgress} = useScroll({
    target: ref,
    start: ["start start", "end start"]
  })

  const yBg = useTransform(scrollYProgress, [0, 1], [0, 1000])

  // useEffect(() => {
  //   // if(!closePopup) {
  //     // Dynamically add the chat script to the DOM inside a custom div
  //     const chatWidgetContainer = document.createElement("div");
  //     chatWidgetContainer.id = "chat-widget-container"; // Custom div for styling

  //     const script = document.createElement("script");
  //     script.id = "chatway";
  //     script.src = "https://cdn.chatway.app/widget.js?id=ZEX9e296yx5j";
  //     script.async = true;

  //     chatWidgetContainer.appendChild(script);
  //     document.body.appendChild(chatWidgetContainer);

  //     return () => {
  //     // Cleanup: Remove the script and container when the component is unmounted
  //     if (chatWidgetContainer) {
  //       document.body.removeChild(chatWidgetContainer);
  //     }
  //     };
  //   // }
  // }, []);

  // useEffect(() => {
  //   // Check if the chat script already exists
  //   const existingScript = document.getElementById("chatway");
  //   if (existingScript) return;

  //   // Dynamically add the chat script
  //   const script = document.createElement("script");
  //   script.id = "chatway";
  //   script.src = "https://cdn.chatway.app/widget.js?id=ZEX9e296yx5j";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     // Cleanup: Remove the script when the component is unmounted
  //     const loadedScript = document.getElementById("chatway");
  //     if (loadedScript) {
  //       document.body.removeChild(loadedScript);
  //     }
  //   };
  // }, []);

  const [loadWidget, setLoadWidget] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) { // Adjust scroll threshold
        setLoadWidget(true);
        window.removeEventListener("scroll", handleScroll); // Load only once
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!loadWidget) return;

    // Dynamically add the chat script
    const script = document.createElement("script");
    script.id = "chatway";
    script.src = "https://cdn.chatway.app/widget.js?id=ZEX9e296yx5j";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      const loadedScript = document.getElementById("chatway");
      if (loadedScript) {
        document.body.removeChild(loadedScript);
      }
    };
  }, [loadWidget]);

  return (
    <div className="hero">
      <div className="overlay"></div>
      {/* <img src={video} /> */}
      <video src={video} autoPlay loop muted playsinline/>
      {/* <div className="youtube-box">
        <ReactPlayer
          url="https://youtu.be/AZ7ReGQcfI4"
          playing
          loop
          muted
          width="100%"
          height="100%"
          config={{
            youtube: {
              playerVars: {
                autoplay: 1,
                controls: 0, // Ensure controls are hidden
                modestbranding: 1,
                rel: 0, // Prevent showing related videos
                showinfo: 0,
                start: 0,
              },
            },
          }}
          className="video-player"
        />
      </div> */}
      {/* <iframe
        src={"https://youtu.be/tMNlpKDXfvY?si=NB3rsF5XtVGcaquJ"}
        title="Background Video"
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      ></iframe> */}
      <motion.div className="hero-content container1" variants = {textVariants} initial="initial" whileInView="animate" style={{ y:yBg}}>
        <motion.h1 variants = {textVariants}>GREAT IDEAS DESERVE TO SHINE. WE BRING THEM TO LIFE WITH <span className="color">2D ANIMATION</span></motion.h1>
        <motion.div className="review-button">
          {/* <a href="https://clutch.co/profile/rabbit-carrot"> */}
          <div className="clutch-review">
                  <div className="left">
                    REVIEWED ON
                    <img src={clutch} alt="" />
                  </div>
                  <div className="right">
                  <div className="rating">
                      <span className="star">&#9733;</span>
                      <span className="star">&#9733;</span>
                      <span className="star">&#9733;</span>
                      <span className="star">&#9733;</span>
                      <span className="star">&#9733;</span>
                    </div>
                    5.0 RATING
                  </div>
                </div>
                {/* </a> */}
          <a href="#about-contact"><button variants = {textVariants} className="btn button-touch2">Let's Discuss
              <span className="line">
                  <span className="circle"></span>
              </span>
          </button></a>
        </motion.div>
      </motion.div>
      <motion.div className="scroll" variants = {textVariants} initial="scrollButtonInitial" animate="scrollButton">
        <img className="mouse" src={mouse} alt="" />
        <a href="#about-contact"><h1>Scroll to explore</h1></a>
      </motion.div>
      <a href="https://wa.me/+971542226464" className="whatsapp">
        <img src={whatsappIcon} alt="" className="whatsapp-icon" />
      </a>
    </div>
  );
}