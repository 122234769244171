import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { AnimatePresence, motion } from 'framer-motion'

import FullScreenImage from './FullScreenImage'
import "./Gallary.css"

import play from "../../../Assets/Common/play-button.svg"
import closeButton from "../../../Assets/Common/close-button.svg"

import twoD1 from "../../../Assets/Works/2d/legalty.webp"
import twoD2 from "../../../Assets/Works/2d/bgx.webp"
import twoD3 from "../../../Assets/Works/2d/bossa.webp"
import twoD4 from "../../../Assets/Works/2d/kareo.webp"
import twoD5 from "../../../Assets/Works/2d/retune.webp"
import twoD6 from "../../../Assets/Works/2d/australian.webp"
import twoD7 from "../../../Assets/Works/2d/employsure.webp"
import twoD8 from "../../../Assets/Works/2d/riverkids.webp"
import twoD9 from "../../../Assets/Works/2d/pillteam.webp"
import twoD10 from "../../../Assets/Works/2d/abudhabi.webp"
import twoD11 from "../../../Assets/Works/2d/bananalab.webp"
import twoD12 from "../../../Assets/Works/2d/yell.webp"

const videoLinks = {
    video_1: "https://youtu.be/JEYTvUvRnK0",  // Legalty
    video_2: "https://youtu.be/a3SWwsjSs48",  //BGX
    video_3: "https://youtu.be/BN6vGiHEMf4",  // Bossa
    video_4: "https://youtu.be/u2DnEYt11f0",  // Kareo
    video_5: "https://youtu.be/4bh4VPn3FG0",  // Retune
    video_6: "https://youtu.be/tx4xrA6QOBw",  // Australian Govt
    video_7: "https://www.youtube.com/watch?v=EOHS_nXdBZ0", // Employsure
    video_8: "https://youtu.be/p2KkFi7Q7RM", // RiverKid
    video_9: "https://youtu.be/UnCDlq511oA", // Pill Team 
    video_10: "https://www.youtube.com/watch?v=FpdW_7qbRdo", // Abudhabi Police
    video_11: "https://www.youtube.com/watch?v=AXKTMIZlOfM", // Banana Lab
    video_12: "https://youtu.be/fy08hspZtmo" // Yell
}

const gallaryVariants = {
  initial: { y: 200, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  exit: { y: -200, opacity: 0, transition: { duration: .5 } },
  // initial1: { x: -100, opacity: 0 },
  // animate1: { x: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  // exit1: { x: -100, opacity: 0, transition: { duration: .5 } },
};

export default function Gallary() {
    const [playingVideo, setPlayingVideo] = useState(null);

    const handleVideoClick = (videoUrl) => {
        setPlayingVideo(videoUrl);
      };

    const [playingImage, setPlayingImage] = useState(null);

    // const handleImageClick = (imageUrl) => {
    //     setPlayingImage(imageUrl);
    //   };

  return (
    <section id="portfolio" className="portfolio">
      {/* <div className="heading-div">
          <h1>
              <hr />Portfolio
          </h1>
      </div> */}

      <h1 className="commonHeading"><span>Portfolio</span></h1>

        <AnimatePresence mode="wait">
        <motion.div 
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants}
            className='image-grid'>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_7)}>
                <img src={twoD7} alt="2D Animation"></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">2D ANIMATION</p>
                      <img className="play-button" src={play} alt="2D Animation Studio"/>
                      <p className="company-name">EMPLOYSURE</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_8)}>
                <img src={twoD8} alt="2D Animation"></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">2D ANIMATION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">RIVERKID</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_9)}>
                <img src={twoD9} alt="2D Animation"></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">2D ANIMATION</p>
                      <img className="play-button" src={play} alt="2D Animation Studio"/>
                      <p className="company-name">PILL TEAM</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_10)}>
                <img src={twoD10} alt="2D Animation"></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">2D ANIMATION</p>
                      <img className="play-button" src={play} alt="2D Animation Studio"/>
                      <p className="company-name">ABUDHABI POLICE</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_11)}>
                <img src={twoD11} alt="2D Animation"></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">2D ANIMATION</p>
                      <img className="play-button" src={play} alt="2D Animation Studio"/>
                      <p className="company-name">BANANA LAB</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_12)}>
                <img src={twoD12} alt="2D Animation"></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">2D ANIMATION</p>
                      <img className="play-button" src={play} alt="2D Animation Studio"/>
                      <p className="company-name">YELL</p>
                    </div>
                </div>
            </div>
          </motion.div>
          <motion.div
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants}
            className='image-grid'>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_1)}>
              <img src={twoD1} alt="2D Animation"></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">2D ANIMATION</p>
                    <img className="play-button" src={play} alt="2D Animation Company"/>
                    <p className="company-name">LEGALTY</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_2)}>
              <img src={twoD2} alt="2D Animation"></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">2D ANIMATION</p>
                    <img className="play-button" src={play} alt="2D Animation Company"/>
                    <p className="company-name">BGX</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_3)}>
              <img src={twoD3} alt="2D Animation"></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">2D ANIMATION</p>
                    <img className="play-button" src={play} alt="2D Animation Company"/>
                    <p className="company-name">BOSSA</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_4)}>
              <img src={twoD4} alt="2D Animation"></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">2D ANIMATION</p>
                    <img className="play-button" src={play} alt="2D Animation Company"/>
                    <p className="company-name">KAREO</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_5)}>
              <img src={twoD5} alt="2D Animation"></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">2D ANIMATION</p>
                    <img className="play-button" src={play} alt="2D Animation Company"/>
                    <p className="company-name">RETUNE</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_6)}>
              <img src={twoD6} alt="2D Animation"></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">2D ANIMATION</p>
                    <img className="play-button" src={play} alt="2D Animation Company"/>
                    <p className="company-name">AUSTRALIAN GOVERNMENT</p>
                  </div>
              </div>
          </div>
          </motion.div>
        </AnimatePresence>

{/* <       AnimatePresence mode="wait"> */}
          {/* <motion.div
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants} */}
            <div
              className="button-container content-center">
            <a href="#about-contact"><button className="btn button-touch2"> Explore More
                    <span className="line">
                        <span className="circle"></span>
                    </span>
            </button></a>
            </div>
          {/* </motion.div> */}
          {/* </AnimatePresence> */}
    
    {playingVideo && (
      <div className="video-overlay">
        <div className="video-player">
          <ReactPlayer className="player" url={playingVideo} playing={true} controls width="100%" height="100%" />
        </div>
          <img className="close-button1" onClick={() => setPlayingVideo(null)} src={closeButton} alt="2D Animation"/>
        {/* <img className="close-button1" src={closeButton} alt=""/> */}
      </div>
      )}
    {playingImage && (
      <div className="video-overlay">
        <div className="video-player">
          <FullScreenImage src={playingImage} />
      </div>
        <img className="close-button1" onClick={() => setPlayingImage(null)} src={closeButton} alt="2D Animation"/>
      </div>
    )}
  </section>
  )
}