import React from 'react'
import { useRef, useState, useEffect } from 'react';
import ReactIframe from 'react-iframe'
import {motion, useScroll, useTransform} from "framer-motion"
import LazyLoad from 'react-lazyload'
import "./AboutUs.css"


function About({ size }) {
  const textVariants = {
    initial: {
      x: -size,
      opacity: 0
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.1
      }
    },
    scrollButtonInitial: {
      opacity: 0
    },
    scrollButton: {
      opacity: 1,
      y: 10,
      transition: {
        duration: 2,
        repeat: Infinity
      }
    }
  } 
    const ref = useRef()
    const {scrollYProgress} = useScroll({
        target: ref,
        start: ["start start", "end start"]
    })

  const yBg = useTransform(scrollYProgress, [0, 1], [-500, 500])

  const [isVisible, setIsVisible] = useState(false);
  const iframeRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.1 }
    );
    if (iframeRef.current) {
      observer.observe(iframeRef.current);
    }
    return () => observer.disconnect();
  }, []);

  const [width, setWidth] = useState(0)

  useEffect(() => {
    // Function to handle resize and set screen width and size accordingly
    const handleResize = () => {
        setWidth(window.innerWidth)
    };
    
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    
    // Initial check in case window width has already changed
    handleResize();
    
    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
}, []);

  return (
        <section id="about-contact" className='aboutHome container1' ref={ref} >
          {/* <div className="heading-div">
              <h1>
                  <hr />About Us
              </h1>
          </div> */}
            <motion.div className='flexSB' style={{yBg}}>
                <div className='left'>
                    <motion.div 
                        initial="initial"
                        whileInView="animate"
                        transition={{
                          duration: 1,
                          ease: "easeOut",
                          delay: 0.2
                        }}
                        exit="exit"
                        viewport={{ once: false, amount: 0.5 }}
                        variants={textVariants}
                        className="aboutHeading">
                        <motion.h1>WHO ARE WE?</motion.h1>
                        <motion.h3>{"Making Your Ideas Come to Life".toUpperCase()}</motion.h3>
                        <motion.h2>We turn your stories into super cool 2D Animations that are fun to watch.</motion.h2>
                        <motion.p>2D Animation is like bringing your ideas to life on a flat canvas. It’s about creating characters, 
                          scenes, and stories that move in a way that feels real-whether it’s a quirky stick figure or a beautifully drawn mascot. 
                          {/* For businesses, 2D animations aren’t just about looking good-they’re about making your message unforgettable. */}
                          <br/><br/>
                          At Rabbit And Carrot, we’ve been turning visions into stunning animations since 2008. 
                          As one of the best 2D Animation studios in Dubai, thousands of clients have trusted us to simplify complex ideas, captivate their audience, and help their ROI grow.</motion.p>
                    </motion.div>
                    {/* <div className='items'>
                            <h2>Creative Mastery</h2>
                            <h2>Strategic Impact</h2>
                            <h2>Innovative Solutions</h2>
                    </div> */}
                </div>
                <div className="right" ref={iframeRef}>
                  {/* <LazyLoad> */}
                  {isVisible && 
                    <ReactIframe
                      aria-label='Get A Quote' 
                      frameborder="0"
                      style={{ padding: '20px', border: 'none' }}
                      title='2D Animation Consultation Form'
                      src='https://forms.zohopublic.com/virtualoffice10491/form/GetAQuote/formperma/lIfmVFP1bp-fpxo8a-1RQEeRi2qrVOvb8-j3CFbWkx0' 
                      width="100%" 
                      height="649px"
                    />
                  }
                  {/* </LazyLoad> */}
                </div>
                </motion.div>
        </section>
  )
}

export default About